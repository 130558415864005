import React from "react";
// import { cred } from "./config";
import { useGoogleMaps } from "react-hook-google-maps";

function Contact() {
  // const { ref, map, google } = useGoogleMaps(
  //   // Use your own API key, you can get one from Google (https://console.cloud.google.com/google/maps-apis/overview)
  //   cred.apiKey,
  //   // NOTE: even if you change options later
  //   {
  //     center: { lat: 0, lng: 0 },
  //     zoom: 3,
  //   }
  // );
  // console.log(map); // instance of created Map object (https://developers.google.com/maps/documentation/javascript/reference/map)
  // console.log(google);
  // return (
  //   <React.Fragment>
  //     <div ref={ref} style={{ width: "100%", height: "60vh" }} />
  //   </React.Fragment>
  // );
}

export default Contact;
